import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { apis } from '../../../environments/environment'

@Injectable()
export class DataService {
    private baseUrl = `${apis.baseUrl}/public`
    private data = new BehaviorSubject<Array<any>>([{ fetching: true }])
    data$ = this.data.asObservable()

    constructor(public http: HttpClient) {
        // code
    }
    payNow(params:any): Observable<any> {
        const url = `${this.baseUrl}/student/online-payment`
        return this.http.post(url, params)
    }

    studentBalance(params: any): Observable<any> {
        const url = `${this.baseUrl}/student/balance-detail`
        return this.http.post(url, params)
    }

    payDetail(params: any): Observable<any> {
        const url = `${this.baseUrl}/student/pay-detail`
        return this.http.post(url, params)
    }
}
