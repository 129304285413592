<div class="signin-container">
    <div class="signin-box">
        <div class="logo-container">
            <!-- <img class="logo" src="assets/images/logo.png" alt="" /> -->
        </div>
        <ng-container *ngIf="student != null">
            <h4 class="text-center title">Online Payment</h4>
            <h6>Name: {{student.name }}</h6>
            <h6>Email: {{ student.email }}</h6>
            <h6 *ngIf="student.balance > -1">Balance: {{'$'+student.balance }}</h6>
            <h6 *ngIf="student.balance < 0">Balance: {{'- $'+(student.balance)*-1}}</h6>

                <div class="row input-container">
                    <div class="col-md-12  form-inputs mb-2">
                        <div class="">
                            <div class="pre">
                                <label class="secondary">USD</label>
                            </div>
                            <input type="number"  class="text-right fsize" min="0"  placeholder="Enter Amount" (ngModelChange)="updateConvenienceFee($event)" [ngModel]="depositAmount" />

                        </div>
                    </div>
                    <div class="col-md-4  form-inputs mb-2">
                        <div class="center-amount">
                            <div class="pre ">
                                <label class="secondary">Convenience Fee</label>
                            </div>
                             <strong class="fsize">${{convenienceFee}}</strong>
                        </div>
                    </div>

                    <!-- <div class="col-md-4  form-inputs mb-2">
                        <div class="">
                            <div class="pre">
                                <label class="secondary">Transaction Fee</label>
                            </div>
                            <strong class="fsize">{{transFee}}</strong>


                        </div>
                    </div> -->
                    <div class="col-md-4  form-inputs mb-2">
                        <div class="center-amount">
                            <div class="pre ">
                                <label class="secondary">Charge Amount</label>
                            </div>
                            <strong class="fsize">${{chargeFee}}</strong>

                        </div>
                        <!-- <input type="text" class="password form-control mt-0" formControlName="amount" class="email" placeholder="Enter Amount" [ngClass]="{ 'form-submitted': f.submitted }" />
                        <div *ngIf="ui.checkErrors(g.amount, f)" class="errors">
                            <p *ngIf="g.amount.errors?.required">amount is required</p>
                        </div> -->
                    </div>
                </div>
                <div class="login-btn-container text-center">
                    <button [disabled]="loginLoading" class="submit" (click)="payNow()" [ngClass]="loginLoading ? 'in-progress' : ''">
                       <strong class="fsize"> Pay Now</strong>
                        <div class="loading-dots"><span></span><span></span><span></span></div>
                    </button>
                </div>

        </ng-container>
    </div>
</div>
