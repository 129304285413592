import { DataService } from './data.service'
import { Component, OnInit } from '@angular/core'
import { ApiService } from 'src/app/services/api.service'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { ActivatedRoute, Router } from '@angular/router'
import { Resp } from 'src/app/interfaces/response'
import { any } from 'codelyzer/util/function';
import { IndexComponent } from '../index/index.component';

@Component({
    selector: 'app-online-payment',
    templateUrl: './online-payment.component.html',
    styleUrls: ['./online-payment.component.css']
})
export class OnlinePaymentComponent implements OnInit {
    dataForm: FormGroup
    data: any
    student: any
    studentId: any
    codeStatus: any
    loginLoading = false
    convenienceFee:any = 0
    transFee:any = 0
    chargeFee:any = 0
    depositAmount:any = 0



    constructor(
        private api: ApiService,
        public ds: DataService,
        private fb: FormBuilder,
        public ui: UIHelpers,
        private alert: IAlertService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.dataForm = this.fb.group({
            amount: new FormControl('', [Validators.required])
        })
    }

    get g() {
        return this.dataForm.controls
    }

    ngOnInit() {
        this.data = this.route.snapshot.paramMap.get('data')
        const decodedString = atob(this.data)

        console.log(decodedString)

        const obj = JSON.parse(decodedString)
        console.log(obj)
        this.studentId = obj
        if(this.studentId > 0){
            this.ds.payDetail({id:this.studentId}).subscribe(res=>{
                if(res.success == true){
                    console.log(res);

                    const amount = res.data.balance
                    if(amount < 0 ){
                        this.convenienceFee = (amount*-1)*(res.data.convenienceFeePercentage/100)
                        this.chargeFee = (-1*res.data.balance)+(+this.convenienceFee) + (+res.data.transFee)
                        this.chargeFee = this.chargeFee.toFixed(2)
                        this.convenienceFee = this.convenienceFee.toFixed(2)
                    }
                    this.transFee = res.data.transFee
                    this.depositAmount = res.data.balance < 0 ?  (-1*res.data.balance) : 0
                    this.depositAmount.toFixed(2)
                    this.student = res.data
                }
            })
            //tslint:disable-next-line: radix

        }
    }

    updateConvenienceFee(val: number) {
        if(val < 0  || val == 0 || val == null){
            val =  0
            this.convenienceFee = 0
            this.chargeFee = 0
        }else{
            console.log('per', this.student.convenienceFeePercentage, 'trans Fee', this.student.transFee);

            this.depositAmount = val
            this.convenienceFee = (this.depositAmount / 100) * this.student.convenienceFeePercentage
            this.convenienceFee = (+this.convenienceFee.toFixed(2)) + (+(+this.student.transFee))
            this.convenienceFee = +(this.convenienceFee.toFixed(2))

            this.chargeFee= +(this.depositAmount) + (+this.convenienceFee)
            this.chargeFee = +(this.chargeFee.toFixed(2))

            this.chargeFee = this.chargeFee.toFixed(2)
            this.convenienceFee = this.convenienceFee.toFixed(2)
        }
    }
    // conveFeeChange(val:number){
    //     this.convenienceFee = +val.toFixed(2)
    //     console.log(this.convenienceFee,val.toFixed(2));

    // }

    payNow(): void {
        this.loginLoading = true
        const params = {

            userId: this.student.id,
            amount: this.depositAmount,
            totalAmount: this.chargeFee,
            convenienceFeePercentage: this.student.convenienceFeePercentage,
            convenienceFee:this.convenienceFee,
            transactionFee:this.student.transFee,
        }
        if (
            this.depositAmount === 0 ||
            this.depositAmount === null ||
            this.depositAmount === undefined ||
            this. depositAmount === ''
        ) {
            this.alert.error('Please Enter Amount')
            this.loginLoading = false
            return
        }

        this.ds.payNow(params).subscribe((resp: Resp<any>) => {
            this.loginLoading = false
            if (resp.success) {
                window.location.href = resp.data
            } else {
                this.alert.error(`Can't pay now. Please try again later.`)
            }
        })
    }
}
